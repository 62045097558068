import React, { useState } from "react";
import { CourseDesc, ReadMoreText } from "../styles/CourseViewStyled";

const MoreLess = ({ desc, theme, selectLangJson }) => {
  const [showMore, setShowMore] = useState(false);
  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }
  return desc ? (
    <div style={{ width: "100%" }}>
      <CourseDesc themeColor={theme}>
        {desc?.length > 150 ? (
          <p>
            {showMore
              ? removeTags(desc)
              : `${removeTags(desc).substring(0, 150)}...`}
          </p>
        ) : (
          <p>{desc}</p>
        )}
      </CourseDesc>
      {desc?.length > 150 ? (
        <ReadMoreText themeColor={theme} onClick={() => setShowMore(!showMore)}>
          <p>
            {desc?.length > 150 ? (showMore ? selectLangJson.read_less : selectLangJson?.read_more) : ""}
          </p>
        </ReadMoreText>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
};

export default MoreLess;
