import React, {
  useState,
  Suspense,
  useEffect,
  lazy,
  useRef,
  startTransition,
} from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { publicRoutes, privateRoutes, courseRoutes } from "./routes";
import { useSelector, useDispatch } from "react-redux";
import * as CS from "./styles/CommonStyled";
import * as LE from "./styles/LoginStyled";
import Navbar from "./components/Navbar";
import { RMlogin } from "./utils/api";
import bannerSample from "./assets/images/bannerSample.png";
import axios, { updateAccessToken } from "./utils/axios";
import Sidebar from "./components/Sidebar/Sidebar";
import backVideo from "./assets/images/backVideo.mp4";
// const Course = lazy(() => import("./pages/private/Courses/Course"));
import Courses from "./pages/private/Courses/CourseContent/Courses";
import LayoutPage from "./Layout";
import VideoComponent from "./VideoComponent";
import {
  setAccessToken,
  setDynamicRoutes,
  setIsActionDisabled,
  setLanguages,
  setOnBoarding,
  setSelectLang,
  setSelectLangJson,
} from "./store/action";
import CryptoJS from "crypto-js";
import { encryptData } from "./utils/Helpers";
import soundIcon from "./assets/images/soundIcon.png";
import democourse1 from "./assets/images/democourse1.png";
import demoImage1 from "./assets/images/demoImage1.png";
import demoImage2 from "./assets/images/demoImage2.png";
import demoImage3 from "./assets/images/demoImage3.png";
import demoImage4 from "./assets/images/demoImage4.png";
import demoImage5 from "./assets/images/demoImage5.png";
import demoImage6 from "./assets/images/demoImage6.png";
import Modal from "./components/Modal/Modal";
import demoImage7 from "./assets/images/demoImage7.png";
import { colorProvider } from "./utils/StylePropertiy";
import * as AS from "./styles/ArrivalStyled";
import { jwtDecode } from "jwt-decode";
const secretKeyMain = "rmLearnerSecretKey";

const PublicRoute = () => {
  const videoRef = useRef(null);
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const [bannerData, setBannerData] = useState([]);
  const getBanner = async () => {
    let url = RMlogin.getBanner;
    try {
      const { data } = await axios.get(url);
      setBannerData(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);
  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };
  return (
    <Routes>
      {publicRoutes.map((val, ind) => {
        return (
          <Route
            exact
            key={ind}
            path={val.path}
            element={
              <Suspense>
                <CS.MainContainer>
                  <CS.InnerContainerTwo>
                    <CS.BackContainer>
                      <video
                        ref={videoRef}
                        className="video-background"
                        width="320"
                        height="240"
                        autoPlay
                        loop
                        muted
                      >
                        <source src={backVideo} type="video/mp4" />
                      </video>
                      <val.component
                        navigate={navigate}
                        location={location}
                        dispatch={dispatch}
                        handlePauseVideo={pauseVideo}
                      />
                      {/* {window.location.pathname === "/" ? ( */}
                      <LE.BannerImageBox>
                        <LE.BannerTitle>
                          {bannerData?.showTextStatus
                            ? `“${bannerData?.showText}”`
                            : ""}
                        </LE.BannerTitle>
                        {bannerData?.showBannerImageStatus ? (
                          <LE.BannerImage
                            href={bannerData?.bannerLink}
                            target="_blank"
                          >
                            <img src={bannerData?.bannerImage} alt="" />
                          </LE.BannerImage>
                        ) : (
                          ""
                        )}
                      </LE.BannerImageBox>
                      {/* ) : (
                        ""
                      )} */}
                    </CS.BackContainer>
                  </CS.InnerContainerTwo>
                </CS.MainContainer>
              </Suspense>
            }
          />
        );
      })}
      <Route path="/*" element={<Navigate to="/learner" />} />
    </Routes>
  );
};
const PrivateRoute = ({ dynamicRoute }) => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const courseId = useSelector((state) => state?.courseId);
  const selectLang = useSelector((state) => state?.selectLang);
  const languages = useSelector((state) => state?.languages);
  const [localState, setLocalState] = useState({});
  useEffect(() => {
    if (selectLang) {
      let filterLangJson = languages?.find((val) => val._id === selectLang);
      dispatch(
        setSelectLangJson(JSON.parse(filterLangJson?.localization_json))
      );
      const modifiedLangJson = {
        ...JSON.parse(filterLangJson?.localization_json),
        // profile_details: "Profile Details",
        // update_your_photo_and_personal_details_here:
        //   "Update your photo and personal details here",
        // user_name: "User Name",
        // email_id: "Email ID",
        // upload_profile: "Upload Profile",
        // cancel: "Cancel",
        // save_changes: "Save Changes",
        // great: "{selectLangJson.great}!",
        // your_data_has_been_successfully_saved:
        //   "Your data has been successfully saved.",
        // continue: "Continue",
        // password: "Password",
        // please_enter_your_current_password_to_change_your_password:
        //   "Please enter your current password to change your password",
        // current_password: "Current password",
        // new_password: "New Password",
        // your_new_password_must_be_more_than_characters:
        //   "Your new password must be more than 8 characters",
        // confirm_new_password: "Confirm new password",
        // update_password: "Update Password",
        // account_settings: "Account Settings",
        // profile_details: "Profile Details",
        // password: "Password",
        // warning: "Warning!",
        // if_you_change_the_video_language:
        //   "If you change the video language, the video will continue from where you left off.",
        // cancel: "Cancel",
        // ok_continue: "Ok, Continue",
        // progress: "progress",
        // leave_a_review: "Leave a review",
        // please_fill_the_feedback_form:
        //   "Please fill the feedback form . Your feedback is valuable for us.",
        // episode_complete: "Episode Completed",
      };
      dispatch(setSelectLangJson(modifiedLangJson));
      console.log(JSON.parse(filterLangJson?.localization_json), "Lang Json");
    }
  }, [selectLang]);

  return (
    <Routes>
      <Route path="/" element={<LayoutPage dynamicRoute={dynamicRoute} />}>
        {privateRoutes.map((val, ind) => (
          <Route
            key={ind}
            path={`${dynamicRoute}${val.path}`}
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <val.component
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  courseId={courseId}
                  localState={localState}
                  dynamicRoute={dynamicRoute}
                />
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route path="/" element={<Courses dynamicRoute={dynamicRoute} />}>
        {courseRoutes.map((val, ind) => (
          <Route
            key={ind}
            path={`${dynamicRoute}${val.path}`}
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <val.component
                  navigate={navigate}
                  dispatch={dispatch}
                  location={location}
                  courseId={courseId}
                  theme={theme}
                  localState={localState}
                  dynamicRoute={dynamicRoute}
                />
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route
        path="/*"
        element={<Navigate to={`${dynamicRoute || ""}/learner/dashboard`} />}
      />
    </Routes>
  );
};

const App = () => {
  // const state = useSelector((state) => state?.userData);
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state?.access_token);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  let location = useLocation();
  let navigate = useNavigate();
  const [backVideo, setBackVideo] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const onBoarding = useSelector((state) => state?.onBoarding);
  const dynamicRoutes = useSelector((state) =>
    state?.dynamicRoutes ? `/${state?.dynamicRoutes}` : ""
  );
  // const [isOpen, setIsOpen] = useState(onBoarding);
  const [isOpen, setIsOpen] = useState(onBoarding);
  const [dynamicRoute, setDynamicRoute] = useState("");
  console.log(isOpen, "isOpen");
  console.log(onBoarding, "onBoarding");
  const [onBoardingScreens, setOnBoardingScreens] = useState({
    firstScreen: true,
    secondScreen: false,
    thirdScreen: false,
    fourthScreen: false,
    fifthScreen: false,
    sixthScreen: false,
  });
  let firstPlay =
    "https://s3rain.s3.ap-south-1.amazonaws.com/1721220010340_WorkFlix+Animation+Logo+.mp4";

  useEffect(() => {
    if (currentTime > 2) {
      if (currentTime === duration) {
        setBackVideo(false);
      }
    }
  }, [currentTime, duration]);

  console.log("tick updated 4:05 PM");
  const [accessTokens, setAccessTokens] = useState(
    new URLSearchParams(location.search).get("accessToken")
  );
  console.log(location.search, "location.search");

  const [timeSt, setTimeSt] = useState(
    new URLSearchParams(location.search).get("timestamp")
  );
  const [userId, setUserId] = useState(
    new URLSearchParams(location.search).get("userId")
  );
  const [otpCode, setOtpCode] = useState(
    new URLSearchParams(location.search).get("code")
  );
  const [screenJump, setScreenJump] = useState(
    new URLSearchParams(location.search).get("type")
  );
  const [data, setData] = useState(
    new URLSearchParams(location.search).get("data")
  );
  const [userType, setuserType] = useState(
    new URLSearchParams(location.search).get("userType")
  );

  console.log(userType, "userType userTypeuserType");
  const getUserList = async (e) => {
    try {
      const decodedUserType = userType?.replace(/['"]+/g, "");
      console.log(
        decodedUserType,
        "decodedUserType decodedUserTypedecodedUserType"
      );
      let url =
        decodedUserType === "client_admin"
          ? `${RMlogin.getClientLearner}${e}?userType=client_admin`
          : `${RMlogin.getClientLearner}${e}`;

      console.log(url, "url urlurlurlurlurl");
      const response = await axios.get(url);
      const { data } = response;
      console.log({ data }, "user list data");

      if (data?.data && data?.data?.HOME_SCREEN) {
        console.log(data, "inner one");
        const encryptedAccessToken = CryptoJS.AES.encrypt(
          data?.token?.accessToken,
          secretKeyMain
        ).toString();
        localStorage.setItem("rainLearnerToken", encryptedAccessToken);
        localStorage.setItem("languagesObj", encryptData(data?.data?.languges));
        const encryptedActionDisbaled = CryptoJS.AES.encrypt(
          data?.data?.ActionDisabled,
          secretKeyMain
        ).toString();
        localStorage.setItem("isActionDisabled", encryptedActionDisbaled);
        updateAccessToken(data?.token?.accessToken);
        dispatch(setAccessToken(data?.token?.accessToken));
        dispatch(setLanguages(data?.data?.languges));
        dispatch(setSelectLang(data?.data?.languges[0]?._id));
        dispatch(setIsActionDisabled(data?.data?.ActionDisabled));
        dispatch(setOnBoarding(true));
        const encryptedSelectLang = CryptoJS.AES.encrypt(
          data?.data?.languges[0]?._id,
          secretKeyMain
        ).toString();
        window.localStorage.setItem("rainLearnerLang", encryptedSelectLang);
        // Navigate to dashboard
        navigate("/learner/dashboard");
      } else {
        console.log("HOME_SCREEN not found in data");
      }
    } catch (error) {
      console.error(
        "Error fetching user list: ",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  useEffect(() => {
    const decodedScreenJump = screenJump?.replace(/['"]+/g, "");
    if (userId && decodedScreenJump === "impersonate") {
      getUserList(userId);
    }
  }, [userId, screenJump]);

  console.log(screenJump, "screenJump screenJump");

  // useEffect(() => {
  //   if (window.location.pathname?.includes("redirect")) {
  //     jumpFun();
  //   }
  // }, [accessTokens, screenJump, userId]);

  const jumpFun = () => {
    localStorage.clear();
    dispatch(setAccessToken(""));
    console.log("Step 1");
    console.log("Step 1", window.location.pathname);

    const decodedScreenJump = screenJump?.replace(/['"]+/g, ""); // Remove extra quotes
    console.log("Decoded screenJump:", decodedScreenJump);

    if (decodedScreenJump === "resetPassword") {
      updateAccessToken(accessTokens);
      // localStorage.setItem("rainLearnerToken", accessTokens);
      console.log("Step 2");
      navigate("/learner/reset-password", {
        state: {
          userId: userId,
        },
      });
    } else if (decodedScreenJump === "impersonate") {
      console.log("Step 3");
      // Handle impersonation logic
    }

    console.log("Step 4");
  };

  const handleScreenClick = (screen) => {
    setOnBoardingScreens({
      firstScreen: false,
      secondScreen: false,
      thirdScreen: false,
      fourthScreen: false,
      fifthScreen: false,
      sixthScreen: false,
      [screen]: true,
    });
  };

  const resetScreens = () => {
    setOnBoardingScreens({
      firstScreen: false,
      secondScreen: false,
      thirdScreen: false,
      fourthScreen: false,
      fifthScreen: false,
      sixthScreen: false,
    });
    setIsOpen(false);
    dispatch(setOnBoarding(false));
  };

  useEffect(() => {
    if (onBoarding) {
      setIsOpen(true);
      setOnBoardingScreens({
        firstScreen: true,
        secondScreen: false,
        thirdScreen: false,
        fourthScreen: false,
        fifthScreen: false,
        sixthScreen: false,
      });
    }
  }, [onBoarding]);

  // const getTokenFun = () => {
  //   const encryptedAccessToken = localStorage.getItem("rainLearnerToken");
  //   if (encryptedAccessToken) {
  //     try {
  //       return CryptoJS.AES.decrypt(
  //         encryptedAccessToken,
  //         secretKeyMain
  //       ).toString(CryptoJS.enc.Utf8);
  //     } catch (error) {
  //       console.error("Token decryption failed", error);
  //     }
  //   }
  //   return null;
  // };

  // useEffect(() => {
  //   const token = getTokenFun();
  //   if (token) {
  //     try {
  //       const decoded = jwtDecode(token);
  //       setDynamicRoute(decoded?.micrositeURL || "");
  //       localStorage.setItem("rainRoute", decoded?.micrositeURL);
  //       dispatch(setDynamicRoutes(decoded?.micrositeURL));
  //     } catch (error) {
  //       console.error("JWT decoding failed", error);
  //     }
  //   }
  // }, []);

  return (
    <>
      <CS.ParentBox>
        {window.location.pathname?.includes("redirect") ? (
          jumpFun()
        ) : access_token ? (
          backVideo ? (
            <VideoComponent
              handleDuration={setDuration}
              handleCurrentTime={setCurrentTime}
              firstPlay={firstPlay}
            />
          ) : (
            <PrivateRoute dynamicRoute={dynamicRoutes}/>
          )
        ) : (
          <PublicRoute />
        )}
      </CS.ParentBox>
      <Modal
        maxWidth={"700px"}
        isOpen={isOpen}
        backColor={colorProvider.darkBlue}
        component={
          <AS.ArrContainer>
            {onBoardingScreens?.firstScreen && (
              <AS.ArrInnerContainer>
                <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst>

                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.welcome_to_workflix}
                      </div>
                      <div className="desc">{selectLangJson?.welcome_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("secondScreen")}
                      >
                        {selectLangJson?.get_started}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.secondScreen && (
              <AS.ArrInnerContainer>
                {/* <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst> */}
                <AS.ArrFirst>
                  <img src={demoImage2} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.course_onboad_head}
                      </div>
                      <div className="desc">{selectLangJson?.course_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("thirdScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.thirdScreen && (
              <AS.ArrInnerContainer>
                {/* <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst> */}
                <AS.ArrFirst>
                  <img src={demoImage3} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.support_onboard_head}
                      </div>
                      <div className="desc">{selectLangJson?.support_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("fourthScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.fourthScreen && (
              <AS.ArrInnerContainer>
                {/* <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst> */}
                <AS.ArrFirst>
                  <img src={demoImage4} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.language_onboard_head}
                      </div>
                      <div className="desc">{selectLangJson?.language_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("fifthScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.fifthScreen && (
              <AS.ArrInnerContainer>
                {/* <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst> */}
                <AS.ArrFirst>
                  <img src={demoImage5} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">{selectLangJson?.dark_head}</div>
                      <div className="desc">{selectLangJson?.dark_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("sixthScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.sixthScreen && (
              <AS.ArrInnerContainer>
                <AS.ArrFirst>
                  <img src={demoImage7} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                      isActive={true}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.profile_head}
                      </div>
                      <div className="desc">{selectLangJson?.profile_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div className="orangebox" onClick={resetScreens}>
                        {selectLangJson?.close}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}
          </AS.ArrContainer>
        }
      />
    </>
    // <CS.ParentBox><PrivateRoute /></CS.ParentBox>
  );
};

export default App;
