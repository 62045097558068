export const USER_DATA = "USER_DATA";
export const IS_DARK = "IS_DARK";
export const IS_TOGGLE = "IS_TOGGLE";
export const ACCESS_MODULE = "ACCESS_MODULE";
export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";
export const DELETE_LENGTH = "DELETE_LENGTH";
export const SIDEBAR_TOGGLE_RESP = "SIDEBAR_TOGGLE_RESP";
export const IS_MODAL = "IS_MODAL";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const COURSE_ID = "COURSE_ID";
export const IS_MUTED = "IS_MUTED";
export const SIDE_CONTENT_DATA = "SIDE_CONTENT_DATA";
export const EPISODEID = "EPISODEID";
export const COMPLETEPROGRESS = "COMPLETEPROGRESS";
export const VIDEOPLAY = "VIDEOPLAY";
export const VIDEOCOUNT = "VIDEOCOUNT";
export const BANNERTHUMB = "BANNERTHUMB";
export const EPISODECOMPLETED = "EPISODECOMPLETED";
export const EPISODEREFRESH = "EPISODEREFRESH";
export const VOLUMEVALUE = "VOLUMEVALUE";
export const BUTTONNAME = "BUTTONNAME";
export const QUIZEPISODE = "QUIZEPISODE";
export const MENUREFRESH = "MENUREFRESH";
export const LANGUAGEID = "LANGUAGEID";
export const SELECTLANG = "SELECTLANG";
export const SELECTLANGJSON = "SELECTLANGJSON";
export const LANGUAGES = "LANGUAGES";
export const EPISODELANGUAGES = "EPISODELANGUAGES";
export const EPISODESELECTLANGUAGE = "EPISODESELECTLANGUAGE";
export const PROFILEUPDATE = "PROFILEUPDATE";
export const EPISODELENGTH = "EPISODELENGTH";
export const TEMPLATEID = "TEMPLATEID";
export const ISACTIONDISABLED = "ISACTIONDISABLED";
export const PROFILEDATA = "PROFILEDATA";
export const ONBOARDING = "ONBOARDING";
export const COURSEMENUDATA = "COURSEMENUDATA";
export const DYNAMICROUTES = "DYNAMICROUTES";
