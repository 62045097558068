import React, { useRef, useState, useEffect } from "react";
import * as SS from "./styles/SectionStyled";
import ReactPlayer from "react-player";

const VideoComponent = ({ firstPlay, handleDuration, handleCurrentTime }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true); // Use this to control playing state

  const onDuration = (duration) => {
    handleDuration(duration); // Pass duration to parent component
    console.log("Video Duration: ", duration, "seconds");
  };

  const onProgress = ({ playedSeconds }) => {
    handleCurrentTime(playedSeconds); // Update current time in parent component
    console.log("Current Time: ", playedSeconds, "seconds");
  };

  return (
    <SS.VideoPlayerOverlay>
      <ReactPlayer
        className="video-ele"
        url={firstPlay}
        width="100%"
        height="100%"
        playing={isPlaying}
        controls={false}
        muted={true}
        onReady={() => console.log("Player is ready")}
        ref={videoRef}
        style={{ borderRadius: "12px" }}
        loop={false}
        playsinline
        onDuration={onDuration}
        onProgress={onProgress}
      />
    </SS.VideoPlayerOverlay>
  );
};

export default VideoComponent;
