import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const QuizTitle = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  @media (max-width: 780px) {
    width: 100%;
  }
  p {
    font-size: ${fontProvider.oneOne};
    font-weight: ${fontWeight.six};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.blackCol};
    margin-left: ${fontProvider.oneOne};
    @media (max-width: 410px) {
      font-size: ${fontProvider.zeroEig};
    }
  }
  .grey-text {
    font-size: ${fontProvider.zeroTen};
    font-weight: ${fontWeight.five};
    color: ${colorProvider.greyThree};
    margin-left: ${fontProvider.zeroFive};
    @media (max-width: 410px) {
      font-size: ${fontProvider.zeroSix};
    }
  }
  #resp-retakes {
    @media (max-width: 780px) {
      margin-left: 3.2rem;
    }
  }
  img {
    height: 35px;
    width: 35px;
    object-fit: ${commonStyles.ob_con};
    cursor: pointer;
  }
`;
export const OrangeButton = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  border-radius: ${commonStyles.sec_br};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.navLink : colorProvider.navLink};
  padding: ${fontProvider.zeroSev} ${fontProvider.zeroTen};
  cursor: ${commonStyles.pointer};
  white-space: nowrap;
  @media (max-width: 410px) {
    padding: ${fontProvider.zeroSix} ${fontProvider.zeroEig};
  }
  p {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.six};
    color: ${colorProvider.white};
    @media (max-width: 410px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
`;
export const InnerFirstTwo = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  gap: 1.3rem;
`;
export const CourseTitleRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  padding: 0.3rem;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackThree : colorProvider.navBackground};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.course_bor};
  border-radius: 12px;
  padding: 1rem 1.3rem;
  @media (max-width: 780px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const CourseTitleRowResp = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  flex-direction: column;
  gap: 1rem;
  /* padding: 0.3rem; */
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackThree : colorProvider.navBackground};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.course_bor};
  border-radius: 12px;
  padding: 1rem 1rem;
  @media (min-width: 780px) {
    display: none;
  }
`;
export const CourseTitleRowGroupOne = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
export const CourseTitleRowGroupTwo = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-top: 1px solid
    ${(props) =>
      props.themeColor ? `${colorProvider.blackTwo}` : colorProvider.blackTwo};
`;
export const CommContainer = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  align-items: ${commonStyles.jc_c};
  gap: 0.7rem;

  .title {
    font-size: ${fontProvider.oneThree};
    font-weight: ${fontWeight.six};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.greyDark};
    @media (max-width: 950px) {
      font-size: ${fontProvider.oneOne};
    }
  }
  .red-title {
    font-size: ${fontProvider.oneThree};
    font-weight: ${fontWeight.six};
    color: ${(props) =>
      props.themeColor
        ? colorProvider.redText
        : props.passed
        ? colorProvider.green
        : colorProvider.redText};
    @media (max-width: 950px) {
      font-size: ${fontProvider.oneOne};
    }
  }
  .green-title {
    font-size: ${fontProvider.oneThree};
    font-weight: ${fontWeight.six};
    color: ${colorProvider.green};
    @media (max-width: 950px) {
      font-size: ${fontProvider.oneOne};
    }
  }
  .desc {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
    @media (max-width: 950px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
`;
export const BorderContainer = styled.div`
  height: 30px;
  width: 1px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  background-color: ${(props) =>
    props.themeColor ? `${colorProvider.blackTwo}` : "#D7DAE0"};
  /* margin: 0 8px; */
`;
// Question Container
export const InnerFirstTwos = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackThree : colorProvider.navBackground};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.com_b};
  border-radius: 12px;
  padding: 25px;
  gap: 1rem;
  margin-bottom: 1.7rem;
  @media (max-width: 410px) {
    padding: 1rem;
  }
`;
export const InfoRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
  p {
    font-size: 0.8rem;
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.dateColor};
    /* color: red; */
    text-transform: none !important;
  }
`;
export const QuestCon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;
export const QuestionContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.course_bor};
  border-radius: 12px;
  padding: 1rem 1.3rem;
  gap: 0.8rem;
  margin-bottom: 1rem;
  text-transform: none !important;
`;
export const InnerQuestionCon = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  padding: 0.5rem;
  gap: 0.8rem;
`;
export const QuestionTitle = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_fs};
  gap: 0.3rem;

  .serial-num {
    font-size: ${fontProvider.zeroNin};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteTwo : colorProvider.blackCol};

    @media (max-width: 410px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
  p {
    font-size: ${fontProvider.zeroNin};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteTwo : colorProvider.blackCol};
    line-height: 22px;
    @media (max-width: 410px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
`;
export const QuestionOption = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  flex-wrap: wrap;
  gap: 0.6rem;
  p {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.dateColor};
    @media (max-width: 400px) {
      font-size: ${fontProvider.zeroSix};
    }
  }
  img {
    height: 17px !important;
    width: 17px !important;
    object-fit: ${commonStyles.ob_con};
    cursor: ${commonStyles.pointer};
    border: none !important;
  }
`;
export const DesciptionContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.navBackground};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackThree}`
      : commonStyles.com_b};
  border-radius: 12px;
  padding: 1rem;
  gap: 1rem;
`;

export const CorrectRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  gap: 0.6rem;

  img {
    height: 20px !important;
    width: 20px !important;
    object-fit: ${commonStyles.ob_con};
    border: none !important;
  }
  p {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.six};
    color: ${(props) =>
      props.themeColor
        ? colorProvider.green
        : props.wrongAns
        ? colorProvider.redText
        : colorProvider.green};
  }
`;
export const DesciptionBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_fs};
  flex-direction: ${commonStyles.fd_col};
  gap: 0.6rem;

  .title {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.six};
    color: ${(props) => (props.themeColor ? colorProvider.greyEig : "#667085")};
  }
  .desc {
    font-size: 0.77rem;
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : "#464C5E"};
    line-height: 20px;
  }
`;
export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"}; */
  gap: 0.7rem;
  margin-top: 1rem;
  /* background-color: #212121; */
`;
export const ButtonGroups = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  gap: 0.7rem;
  padding-top: 1rem;
  margin-top: 1rem;
  /* background-color: #212121; */
`;
export const GreenContinue = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #17b26a;
  border-radius: 8px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0.85rem 1rem 0.85rem 1.3rem;
  gap: 0.5rem;
  cursor: pointer;

  @media (max-width: 769px) {
    margin-top: 1rem;
  }
`;
export const SaveButton = styled.button`
  background-color: #f6591c;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
  color: #ffffff;
  /* width: 100%; */
  outline: none;
  border: none;
  white-space: nowrap;
  padding: 0.6rem 1rem;
  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};
  @media (max-width: 410px) {
    font-size: 0.7rem;
  }
  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  /* @media (max-width: 351px) {
    font-size: 1rem;
  } */

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }

  :hover {
    background-color: #417690;
  }
`;
export const CancelButton = styled.button`
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  border-radius: 8px;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#ffffff" : "#464c5e")};
  font-weight: 600;
  /* width: 100%; */
  outline: none;
  padding: 0.6rem 1rem;
  margin-right: 0.5rem;
  white-space: nowrap;
  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  @media (max-width: 410px) {
    font-size: 0.7rem;
  }

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }

  /* :hover {
    background-color: #417690;
  } */
`;
export const RatingContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  gap: 0.4rem;
`;
export const RatingContainerStar = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  gap: 0.4rem;
  img {
    height: 30px;
    margin-right: 0.4rem;
    width: 30px;
    cursor: pointer;
    object-fit: ${commonStyles.ob_con};
    transform: scale(1.1);
    @media (max-width: 410px) {
      width: 25px;
      height: 25px;
    }
  }
`;
export const ComSmily = styled.div`
  position: relative;

  .cross-icon {
    height: 12px;
    width: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.themeColor ? colorProvider.darkBlue : colorProvider.white};
    border-radius: 50%;
    position: absolute;
    top: 0;
    margin-top: -0.5rem;
    margin-left: 1.5rem;

    img {
      border-radius: 50%;
      height: 7px !important;
      width: 7px !important;
      object-fit: contain;
      margin: 0 !important;
    }
  }
  img {
    height: 30px;
    margin-right: 0.4rem;
    width: 30px;
    cursor: pointer;
    object-fit: ${commonStyles.ob_con};
    transform: scale(1.1);
    @media (max-width: 410px) {
      width: 25px;
      height: 25px;
    }
  }

  :hover {
    transform: scale(1.1);
  }
`;
export const ComSmilyTwo = styled.div`
  position: relative;

  .cross-icon {
    height: 12px;
    width: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aqua;
    border-radius: 50%;
    position: absolute;

    img {
      border-radius: 50%;
      height: 10px !important;
      width: 10px !important;
      object-fit: contain;
    }
  }
  img {
    height: 30px;
    margin-right: 0.4rem;
    width: 30px;
    cursor: pointer;
    object-fit: ${commonStyles.ob_con};
    transform: scale(1.1);

    @media (max-width: 410px) {
      width: 25px;
      height: 25px;
    }
  }
`;
export const PagiButton = styled.div`
  border: 1px solid #d7dae0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  left: 0;
  margin-left: 1rem;
  border: ${(props) =>
    props.themeColor ? "1px solid #334155" : "1px solid #D7DAE0"};
  background-color: ${(props) =>
    props.themeColor ? "#161B26" : "transparent"};

  p {
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    font-weight: 500;
  }
  img {
    height: 14px;
    width: 14px;
    object-fit: contain;
  }
`;
export const PagiButtonNext = styled.div`
  border: 1px solid #d7dae0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  right: 0;
  margin-right: 1rem;
  border: ${(props) =>
    props.themeColor ? "1px solid #334155" : "1px solid #D7DAE0"};
  background-color: ${(props) =>
    props.themeColor ? "#161B26" : "transparent"};

  p {
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    font-weight: 500;
  }
  img {
    height: 14px;
    width: 14px;
    object-fit: contain;
  }
`;
export const ComTitle = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-size: ${fontProvider.zeroEig};
  font-weight: 500;
`;
export const OrangeText = styled.div`
  color: ${(props) => (props.themeColor ? "#F05D24" : "#F05D24")};
  font-size: ${fontProvider.zeroEig};
  cursor: ${commonStyles.pointer};
  font-weight: 500;
`;
export const ComIcon = styled.div`
  img {
    height: 18px;
    width: 18px;
    object-fit: ${commonStyles.ob_con};
  }
`;
