import React, { useState } from "react";
import { EpisodeDesc, EpisodeReadMoreText } from "../styles/CourseViewStyled";

const MoreLessEpisode = ({ desc, theme, selectLangJson }) => {
  const [showMore, setShowMore] = useState(false);
  // function removeTags(str) {
  //   if (str === null || str === "") return false;
  //   else str = str?.toString();

  //   // Regular expression to identify HTML tags in
  //   // the input string. Replacing the identified
  //   // HTML tag with a null string.
  //   return str?.replace(/(<([^>]+)>)/gi, "");
  // }
  return (
    <>
      {desc ? (
        <EpisodeDesc themeColor={theme}>
          <p>{showMore ? desc : `${desc?.substring(0, 190)}`}</p>
        </EpisodeDesc>
      ) : (
        ""
      )}
      {desc?.length > 190 ? (
        <EpisodeReadMoreText
          themeColor={theme}
          onClick={() => setShowMore(!showMore)}
        >
          <p> {showMore ? selectLangJson.read_less : selectLangJson?.read_more}</p>
        </EpisodeReadMoreText>
      ) : (
        ""
      )}
    </>
  );
};

export default MoreLessEpisode;
