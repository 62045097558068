import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const FormContainer = styled.div`
  width: ${commonStyles.wid};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "100%")};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
`;
export const InnerFormContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  padding: 0 2rem;
  /* background: #00000066; */

`;
export const InputBoxContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  flex-wrap: wrap;
`;
export const FormTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  font-weight: 500;
  color: #24262d;
  /* border-bottom: ${commonStyles.com_b}; */
  font-family: "EB Garamond", serif;
  text-transform: capitalize;
  padding-bottom: 0.8rem;
`;
export const InputBox = styled.div`
  /* width: ${(props) => (props.wiDth ? props.wiDth : "30%")}; */
  width: ${(props) => (props.wiDth ? props.wiDth : "100%")};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  margin-bottom: 10px;
`;
export const ButtonGroup = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fe};
  align-items: ${commonStyles.ai_c};
  /* border-top: 1px solid #d7dae0; */
  gap: 0.7rem;
`;
export const CancelButton = styled.button`
  border: 1px solid #d7dae0;
  border-radius: 8px;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  color: #464c5e;
  font-weight: 600;
  outline: none;
  padding: 0.6rem 1rem;
  margin: 1.6rem 0;
  margin-right: 0.5rem;
  cursor: pointer;
  background-color: #ffffff;

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }
`;
export const SaveButton = styled.button`
  background-color: #f6591c;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
  color: #ffffff;
  /* width: 100%; */
  outline: none;
  border: none;
  padding: 0.6rem 1rem;
  margin: 1.6rem 0;
  margin-right: 0.5rem;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }

  :hover {
    background-color: #417690;
  }
`;
// Question and Option
export const QuestionOptionBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  margin-bottom: ${fontProvider.zeroSev};
`;
export const QuestionTitle = styled.div`
  width: ${commonStyles.wid};
  font-size: ${fontProvider.zeroNin};
  font-weight: ${fontWeight.five};
  color: ${colorProvider.white};
  margin-bottom: ${fontProvider.zeroEig};
`;
export const OptionsContainerRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
`;
export const OptionsContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  line-height: 20px;
`;
export const OptionButton = styled.div`
  cursor: pointer;
  img {
    height: 15px;
    width: 15px;
    object-fit: ${commonStyles.ob_con};
    margin-right: 7px;
  }
`;
export const Options = styled.div`
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${colorProvider.greyDark};
  margin-bottom: 8px;
  text-transform: capitalize;
`;
export const ErrorText = styled.p`
  margin-top: 8px;
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${colorProvider.red};
  margin-bottom: 8px;
  text-transform: capitalize;
`;
export const VideoBarContainer = styled.div`
  width: ${commonStyles.wid};
  background-color: ${colorProvider.greyTer};
  height: 3px;
`;
export const ProgressBar = styled.div`
  height: 3px;
  background-color: ${colorProvider.orange};
`;
