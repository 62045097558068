import React, { useEffect, useState, useRef, createRef } from "react";
import * as CS from "../../../../styles/CourseViewStyled";
import * as QS from "../../../../styles/QuizStyled";
import darkTitleBottom from "../../../../assets/images/darkTitleBottom.png";
import lightTitleBottom from "../../../../assets/images/lightTitleBottom.png";
import darkTitleTop from "../../../../assets/images/darkTitleTop.png";
import lightTitleTop from "../../../../assets/images/lightTitleTop.png";
import greenCheck from "../../../../assets/images/greenCheck.png";
import greyClock from "../../../../assets/images/greyClock.png";
import rewardIcon from "../../../../assets/images/rewardIcon.png";
import * as SS from "../../../../styles/SectionStyled";
import bannerImage from "../../../../assets/images/bannerImage.jpeg";
import rewardsWatch from "../../../../assets/images/rewardsWatch.png";
import MoreLessEpisode from "../../../../components/MoreLessEpisode";
import { Course, NextTab } from "../../../../utils/api";
import rewardLightsUnWatch from "../../../../assets/images/rewardLightsUnWatch.png";
import rewardLight from "../../../../assets/images/rewardLight.png";
import episodePlay from "../../../../assets/images/episodePlay.png";
import * as FS from "../../../../styles/FormStyled";
import playBoto from "../../../../assets/images/playBoto.png";
import axios from "../../../../utils/axios";
import { get } from "lodash";
import {
  convertTimeFormat,
  percentageTimeRemaining,
} from "../../../../utils/Helpers";
import {
  setEpisodeId,
  setEpisodeLength,
  setVideoCount,
  setVideoPlay,
} from "../../../../store/action";
import { useSelector, useDispatch } from "react-redux";
import ModalTwo from "../../../../components/VideoModal/ModalTwo";
import VideoJS from "../../../../components/Video/VideoMedia";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import * as MS from "../../../../styles/ModelStyled";
import { colorProvider } from "../../../../utils/StylePropertiy";
import Modal from "../../../../components/Modal/Modal";
import crossIcon from "../../../../assets/images/crossIcon.png";
import ReactHlsPlayer from "react-hls-player";
import EpisodeNotPlay from "../../../../assets/images/EpisodeNotPlay.png";
import CircleLoaderTwo from "../../../../components/Loader/CircleLoaderTwo";
import ModalComplete from "../../../../components/Modal/ModalComplete";
import * as SEC from "../../../../styles/SectionStyledTwo";
import successModal from "../../../../assets/images/warns.png";

const CourseContent = ({
  theme,
  courseId,
  localState,
  dispatch,
  navigate,
  isChanging,
}) => {
  const playerRefs = useRef([]);
  const videoCount = useSelector((state) => state?.videoCount);
  const bannerThumb = useSelector((state) => state?.bannerThumb);
  const episodeRefresh = useSelector((state) => state?.episodeRefresh);
  const selectLang = useSelector((state) => state?.selectLang);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const languageId = useSelector((state) => state?.languageId);
  const courseMenuData = useSelector((state) => state?.courseMenuData);
  const isActionDisabled = useSelector((state) => state?.isActionDisabled);
  const dynamicRoutes = useSelector((state) =>
    state?.dynamicRoutes ? `/${state?.dynamicRoutes}` : ""
  );
  const [isTrailer, setIsTrailer] = useState(true);
  const [isEpisode, setIsEpisode] = useState(true);
  const [isTrailerVideo, setIsTrailerVideo] = useState(true);
  const [isPopPo, setIsPopPo] = useState(false);
  const [trailerUrl, setTrailerUrl] = useState("");
  const [courseEpisodeData, setCourseEpisodeData] = useState([]);
  const videoRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [episodeDetails, setEpisodeDetails] = useState({});
  const [quizCount, setQuizCount] = useState(0);
  const [count, setCount] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [isTrailerPlay, setIsTrailerPlay] = useState(false);
  const [episodeLoading, setEpisodeLoading] = useState(false);
  const [isNextTab, setIsNextTab] = useState(false);
  const [isLoaderAdd, setIsLoaderAdd] = useState(false);
  const [videoURL1, setvideoURL1] = useState("");
  const [videoURL2, setvideoURL2] = useState("");
  const [currentURL, setCurrentURL] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const videoPlayerRef = useRef([]);
  const handleClose = (e) => {
    setIsOpen(e);
  };

  const getCourseEpisodeData = async () => {
    setEpisodeLoading(true);
    let url = Course.getCourseEpisodeData;
    const formValues = {
      courseId: courseId,
    };
    if (languageId) {
      languageId && (formValues["languageId"] = languageId);
    }
    try {
      const { data } = await axios.post(url, formValues);
      setEpisodeLoading(false);
      if (data?.result) {
        setCourseEpisodeData(data?.result[0]);
        dispatch(setEpisodeLength(data?.result[0]?.course_episodes));
      }
    } catch (error) {
      setEpisodeLoading(false);
      console.log(error.response.data.message);
    }
  };
  useEffect(() => {
    getCourseEpisodeData();
  }, [courseId, episodeRefresh, languageId]);
  useEffect(() => {
    const totalQuizzes = get(courseEpisodeData, "course_episodes", [])?.reduce(
      (acc, val) => {
        return acc + (val.assessmentQuiz ? 1 : 0);
      },
      0
    );

    setQuizCount(totalQuizzes);
  }, [courseEpisodeData]);

  const handleVideoEnd = () => {
    if (currentURL === videoURL1) {
      setIsLoaderAdd(true);
      setCurrentURL(videoURL2); // Switch to the second video
      if (videoPlayerRef.current) {
        var playPromise = videoPlayerRef?.current?.play();

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              // videoPlayerRef?.current?.play();
              setIsPlaying(true);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      // setIsPaused(false); // Autoplay the next video
    } else {
      // Optionally reset to the first video or handle end of playlist
      console.log("End of playlist");
    }
  };

  const handleMetadatas = (e) => {
    setIsLoaderAdd(false);
  };

  const getQuizStatus = (e, val) => {
    if (e === "allow_result") {
      return (
        <CS.EpisodeSecond themeColor={theme}>
          <CS.CompleteImageBox>
            <img src={theme ? rewardIcon : rewardLight} alt="" />
          </CS.CompleteImageBox>
          <CS.SecondInnerFirst>
            <CS.CompleteContentBox themeColor={theme}>
              <div className="row-de">
                <p className="quiz-title">{val.assessmentQuiz.title} </p>
                <p className="retake-text">
                  {val.assessmentQuiz.allowTake === -1
                    ? ""
                    : `(${val.assessmentQuiz.allowTake} ${selectLangJson.retakes})`}
                </p>
                <img src={greenCheck} alt="" />
              </div>
              <p className="desc-text">
                {val.assessmentQuiz.QuestionCount} {selectLangJson.questions}
              </p>
            </CS.CompleteContentBox>
            <CS.ViewResult
              onClick={() => {
                navigate(`${dynamicRoutes}/learner/quiz-result`, {
                  state: {
                    _id: val?.assessmentQuiz?.user_quizzes?._id,
                  },
                });
              }}
            >
              <p>{selectLangJson.view_result}</p>
            </CS.ViewResult>
          </CS.SecondInnerFirst>
        </CS.EpisodeSecond>
      );
    } else if (e === "take_quiz") {
      return (
        <CS.EpisodeSecond themeColor={theme}>
          <CS.CompleteImageBox>
            <img src={theme ? rewardIcon : rewardLight} alt="" />
          </CS.CompleteImageBox>
          <CS.SecondInnerFirst>
            <CS.CompleteContentBox themeColor={theme}>
              <div className="row-de">
                <p className="quiz-title">{val.assessmentQuiz.title} </p>
                <p className="retake-text">
                  {val.assessmentQuiz.allowTake === -1
                    ? ""
                    : `(${val.assessmentQuiz.allowTake} ${selectLangJson.retakes}})`}
                </p>
              </div>
              <p className="desc-text">
                {val.assessmentQuiz.QuestionCount} {selectLangJson.questions}
              </p>
            </CS.CompleteContentBox>
            <QS.OrangeButton
              onClick={() => {
                navigate(`${dynamicRoutes}/learner/quiz`, {
                  state: {
                    _id: val.assessmentQuiz._id,
                  },
                });
              }}
            >
              <p>{selectLangJson.take_quiz}</p>
            </QS.OrangeButton>
          </CS.SecondInnerFirst>
        </CS.EpisodeSecond>
      );
    } else if (e === "disable") {
      return (
        <CS.EpisodeSecond themeColor={theme} style={{ alignItems: "center" }}>
          <CS.CompleteImageBox>
            <img
              src={
                true ? (theme ? rewardsWatch : rewardLightsUnWatch) : rewardIcon
              }
              alt=""
            />
          </CS.CompleteImageBox>
          <CS.SecondInnerFirst>
            <CS.CompleteContentBox watchColor={true} themeColor={theme}>
              <div className="row-de">
                <p className="quiz-title">{val.assessmentQuiz.title}</p>
              </div>
              <p className="desc-text">
                {val.assessmentQuiz.QuestionCount} {selectLangJson.questions}
              </p>
            </CS.CompleteContentBox>
          </CS.SecondInnerFirst>
        </CS.EpisodeSecond>
      );
    } else if (e === "retake_quiz") {
      return (
        <CS.EpisodeSecond themeColor={theme}>
          <CS.CompleteImageBox>
            <img src={theme ? rewardIcon : rewardLight} alt="" />
          </CS.CompleteImageBox>
          <CS.SecondInnerFirst>
            <CS.CompleteContentBox themeColor={theme}>
              <div className="row-de">
                <p className="quiz-title">{val.assessmentQuiz.title} </p>
                <p className="retake-text">
                  {val.assessmentQuiz.allowTake === -1
                    ? ""
                    : `(${val.assessmentQuiz.allowTake} ${selectLangJson.retakes})`}
                </p>
                <img src={greenCheck} alt="" />
              </div>
              <p className="desc-text">
                {val.assessmentQuiz.QuestionCount} {selectLangJson.questions}
              </p>
            </CS.CompleteContentBox>
            <QS.OrangeButton
              className={isActionDisabled ? "not-allowed" : ""}
              onClick={() => {
                if (!isActionDisabled) {
                  navigate(`${dynamicRoutes}/learner/quiz`, {
                    state: {
                      _id: val.assessmentQuiz._id,
                    },
                  });
                }
              }}
            >
              <p>{selectLangJson.retake_quiz}</p>
            </QS.OrangeButton>
          </CS.SecondInnerFirst>
        </CS.EpisodeSecond>
      );
    } else {
      return "";
    }
  };

  const handleFullScreen = () => {
    if (videoRef.current) {
      if (!document.fullscreenElement) {
        if (videoRef.current.requestFullscreen) {
          videoRef.current.requestFullscreen();
        } else if (videoRef.current.webkitRequestFullscreen) {
          /* Safari */
          videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) {
          /* IE11 */
          videoRef.current.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
      }
    }
  };

  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [trailerVideoSrc, setTrailerVideoSrc] = useState("");
  const [isOpenPolicys, setIsOpenPolicys] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const handleVideoClose = (e) => {
    setIsOpenVideo(e);
  };
  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";

  const nextTab = async () => {
    setIsNextTab(true);
    let url = NextTab.nextTab;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        tabName: "courses-content",
        languageId: selectLang,
      });
      setIsNextTab(false);
      if (data?.data?.model?.message) {
        setIsOpenPolicys(true);
        setModalMsg(data?.data?.model?.message);
      }
      if (data?.data?.Commitee) {
        return navigate(`${dynamicRoutes}/learner/courses/committee`);
      }
      if (data?.data?.QUIZ_SCREEN) {
        return navigate(`${dynamicRoutes}/learner/courses/quiz-result`);
      }
      if (data?.data?.VIEW_POLICY_DOCUMENTS) {
        return navigate(`${dynamicRoutes}/learner/courses/policy-document`);
      }
    } catch (error) {
      console.log(error.response.data.message);
      setIsNextTab(false);
    }
  };
  const handleMetadata = (index, timeStr = "00:00:00") => {
    const parts = timeStr?.split(":");
    const hours = parseInt(parts[0], 10);
    const minutes = parts[1] ? parseInt(parts[1], 10) : 0;
    const seconds = parts[2] ? parseInt(parts[2], 10) : 0;
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

    const player = playerRefs.current[index];
    if (player && player.current) {
      player.current.currentTime = totalSeconds || 0;
    }
  };

  useEffect(() => {
    playerRefs.current =
      courseEpisodeData?.course_episodes?.map(
        (_, index) => playerRefs.current[index] || React.createRef()
      ) || [];
  }, [courseEpisodeData]);
  let firstPlay =
    "https://s3rain.s3.ap-south-1.amazonaws.com/1721220010340_WorkFlix+Animation+Logo+.mp4";

  const getMediaIconPlay = (val, index) => {
    if (
      val?.user_episode_details?.url &&
      val?.last_time_stamp !== "00:00:00" &&
      val.last_time_stamp !== val.end_time_stamp
    ) {
      return (
        <CS.MediaPlayerCon>
          <CS.EpisodeVideoContainer>
            <ReactHlsPlayer
              className="video-class-element"
              playerRef={playerRefs.current[index]}
              src={val?.user_episode_details?.url}
              muted={true}
              autoPlay={false}
              volume={0.5}
              controls={false}
              onLoadedMetadata={() =>
                setTimeout(() => handleMetadata(index, val?.last_time_stamp), 0)
              }
              width="120px"
              height="80px"
            />
          </CS.EpisodeVideoContainer>
          <img
            className="play-butttonon-buttons"
            src={episodePlay}
            alt=""
            onClick={() => {
              if (val?.user_episode_details?.url) {
                dispatch(setEpisodeId(val?._id));
                dispatch(setVideoPlay(true));
                dispatch(setVideoCount(videoCount + 1));
              } else {
                return "";
              }
            }}
          />
          <CS.ProgressContainer>
            <CS.ProgressBar
              style={{
                width: `${percentageTimeRemaining(
                  val?.user_episode_details?.end_time_stamp,
                  val?.user_episode_details?.last_time_stamp
                )}%`,
              }}
            ></CS.ProgressBar>
          </CS.ProgressContainer>
        </CS.MediaPlayerCon>
      );
    } else if (val?.user_episode_details?.url) {
      return (
        <div>
          <img src={val.thumbnailImage} alt="" />
          <img
            className={`play-butttonon-button ${
              isActionDisabled ? "not-allowed" : ""
            }`}
            src={episodePlay}
            alt=""
            onClick={() => {
              if (!isActionDisabled && val?.user_episode_details?.url) {
                dispatch(setEpisodeId(val?._id));
                dispatch(setVideoPlay(true));
                dispatch(setVideoCount(videoCount + 1));
              } else {
                return "";
              }
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <img src={val.thumbnailImage} alt="" />;
          <img
            className={`play-butttonon-button ${
              isActionDisabled ? "not-allowed" : ""
            }`}
            src={EpisodeNotPlay}
            alt=""
            onClick={() => {
              setIsPopPo(true);
            }}
          />
        </div>
      );
    }
  };

  const shouldShowNextButton = (courseMenu) => {
    const feedbackIndex = courseMenu?.findIndex(
      (menu) => menu.key === "course_content"
    );

    if (feedbackIndex !== -1 && feedbackIndex + 1 < courseMenu?.length) {
      const nextItem = courseMenu[feedbackIndex + 1];
      if (nextItem.key === "leaderboard") {
        return false;
      }
      return true;
    }
    return false;
  };

  const showNextButton = shouldShowNextButton(courseMenuData);

  const handleNextClick = (courseMenu) => {
    const feedbackIndex = courseMenu?.findIndex(
      (menu) => menu.key === "course_content"
    );

    if (feedbackIndex !== -1 && feedbackIndex + 1 < courseMenu?.length) {
      const nextItem = courseMenu[feedbackIndex + 1];
      if (nextItem.key === "quiz_result") {
        navigate(`${dynamicRoutes}/learner/courses/quiz-result`);
      } else if (nextItem.key === "policy_documents") {
        navigate(`${dynamicRoutes}/learner/courses/policy-document`);
      } else if (nextItem.key === "commitee") {
        navigate(`${dynamicRoutes}/learner/courses/committee`);
      } else if (nextItem.key === "feedback") {
        navigate(`${dynamicRoutes}/learner/courses/feedback`);
      } else if (nextItem.key === "certificate") {
        navigate(`${dynamicRoutes}/learner/courses/certificate`);
      } else if (nextItem.key === "course_content") {
        navigate(`${dynamicRoutes}/learner/courses-content`);
      }
    }
  };

  return (
    <>
      <CS.InnerFirstTwo themeColor={theme}>
        <CS.CourseTitleRow
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <CS.ComTitle themeColor={theme}>
            <p>{selectLangJson.trailers}</p>
            <span>
              ({get(courseEpisodeData, "trailers", [])?.length}{" "}
              {selectLangJson?.trailers})
            </span>
          </CS.ComTitle>
          <CS.ComTitle
            onClick={() => {
              setIsTrailer(!isTrailer);
            }}
          >
            <img
              src={
                isTrailer
                  ? theme
                    ? darkTitleTop
                    : lightTitleTop
                  : theme
                  ? darkTitleBottom
                  : lightTitleBottom
              }
              alt=""
            />
          </CS.ComTitle>
        </CS.CourseTitleRow>
        {isTrailer ? (
          <CS.EpisodeList themeColor={theme}>
            {episodeLoading ? (
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Skeleton
                    width={20}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={20}
                    // style={{ borderRadius: "12px" }}
                  />
                  <Skeleton
                    width={130}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={80}
                    style={{ borderRadius: "12px" }}
                  />
                  <div>
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                    <Skeleton
                      width={300}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px", marginTop: "1rem" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "right",
                    }}
                  >
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Skeleton
                    width={20}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={20}
                    // style={{ borderRadius: "12px" }}
                  />
                  <Skeleton
                    width={130}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={80}
                    style={{ borderRadius: "12px" }}
                  />
                  <div>
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                    <Skeleton
                      width={300}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px", marginTop: "1rem" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "right",
                    }}
                  >
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              get(courseEpisodeData, "trailers", [])?.map((val, ind) => {
                return (
                  <CS.EpisodesCard
                    key={ind}
                    themeColor={theme}
                    style={{ margin: 0 }}
                  >
                    <CS.EpisodeContent>
                      <CS.EpisodeFirst>
                        <CS.EpisodeImageBox>
                          <CS.SerialNumber themeColor={theme}>
                            {/* <p>{`${selectLangJson[`number_${ind+1}`]}`}</p> */}
                            <p>{ind + 1}</p>
                          </CS.SerialNumber>
                          <CS.ImageBoxTrailer>
                            <img
                              src={get(bannerThumb, "thumbUrl", "")}
                              alt=""
                            />
                            <img
                              className="play-butttonon"
                              src={episodePlay}
                              onClick={() => {
                                setIsOpenVideo(true);
                                setvideoURL1(val?.logo);
                                setCurrentURL(val?.logo);
                                setvideoURL2(val?.convert_url);
                              }}
                              alt=""
                            />
                          </CS.ImageBoxTrailer>
                        </CS.EpisodeImageBox>
                        <CS.EpisodeContentBox>
                          <CS.EpisodeTitleRow>
                            <CS.EpisodeTitle themeColor={theme}>
                              <p>{val.title}</p>
                            </CS.EpisodeTitle>
                            <CS.EpisodeTime themeColor={theme}>
                              <img src={greyClock} alt="" />
                              <p>
                                {convertTimeFormat(
                                  get(val, "videoTime", "00:00:00"),
                                  selectLangJson.min
                                )}
                              </p>
                            </CS.EpisodeTime>
                          </CS.EpisodeTitleRow>
                          <MoreLessEpisode
                            desc={val.description}
                            theme={theme}
                          />
                        </CS.EpisodeContentBox>
                      </CS.EpisodeFirst>
                    </CS.EpisodeContent>
                  </CS.EpisodesCard>
                );
              })
            )}
          </CS.EpisodeList>
        ) : (
          ""
        )}
      </CS.InnerFirstTwo>
      <CS.InnerFirstTwo themeColor={theme}>
        <CS.CourseTitleRow
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <CS.ComTitle themeColor={theme}>
            <p>{selectLangJson.episodes_and_quizzes}</p>
            <span>
              ({get(courseEpisodeData, "course_episodes", [])?.length}{" "}
              {selectLangJson.episodes}, {quizCount} {selectLangJson.quizzes})
            </span>
          </CS.ComTitle>
          <CS.ComTitle
            onClick={() => {
              setIsEpisode(!isEpisode);
            }}
          >
            <img
              src={
                isEpisode
                  ? theme
                    ? darkTitleTop
                    : lightTitleTop
                  : theme
                  ? darkTitleBottom
                  : lightTitleBottom
              }
              alt=""
            />
          </CS.ComTitle>
        </CS.CourseTitleRow>
        {isEpisode ? (
          <CS.EpisodeList themeColor={theme}>
            {episodeLoading ? (
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Skeleton
                    width={20}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={20}
                    // style={{ borderRadius: "12px" }}
                  />
                  <Skeleton
                    width={130}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={80}
                    style={{ borderRadius: "12px" }}
                  />
                  <div>
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                    <Skeleton
                      width={300}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px", marginTop: "1rem" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "right",
                    }}
                  >
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Skeleton
                    width={20}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={20}
                    // style={{ borderRadius: "12px" }}
                  />
                  <Skeleton
                    width={130}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={80}
                    style={{ borderRadius: "12px" }}
                  />
                  <div>
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                    <Skeleton
                      width={300}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px", marginTop: "1rem" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "right",
                    }}
                  >
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Skeleton
                    width={20}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={20}
                    // style={{ borderRadius: "12px" }}
                  />
                  <Skeleton
                    width={130}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={80}
                    style={{ borderRadius: "12px" }}
                  />
                  <div>
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                    <Skeleton
                      width={300}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px", marginTop: "1rem" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "right",
                    }}
                  >
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Skeleton
                    width={20}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={20}
                    // style={{ borderRadius: "12px" }}
                  />
                  <Skeleton
                    width={130}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={80}
                    style={{ borderRadius: "12px" }}
                  />
                  <div>
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                    <Skeleton
                      width={300}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px", marginTop: "1rem" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "right",
                    }}
                  >
                    <Skeleton
                      width={130}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              get(courseEpisodeData, "course_episodes", [])?.map(
                (val, index) => {
                  return (
                    <CS.EpisodesCard
                      key={index}
                      themeColor={theme}
                      style={{ margin: 0 }}
                    >
                      <CS.EpisodeContent>
                        <CS.EpisodeFirst>
                          <CS.EpisodeImageBox>
                            <CS.SerialNumber themeColor={theme}>
                              <p>{index + 1}</p>
                            </CS.SerialNumber>
                            <CS.ImageBox>
                              {getMediaIconPlay(val, index)}
                              {/* {val?.user_episode_details?.url &&
                              val?.last_time_stamp !== "00:00:00" &&
                              val.last_time_stamp !== val.end_time_stamp ? (
                                <CS.MediaPlayerCon>
                                  <CS.EpisodeVideoContainer>
                                    <ReactHlsPlayer
                                      className="video-class-element"
                                      playerRef={playerRefs.current[index]}
                                      src={val?.user_episode_details?.url}
                                      muted={true}
                                      autoPlay={false}
                                      volume={0.5}
                                      controls={false}
                                      onLoadedMetadata={() =>
                                        setTimeout(
                                          () =>
                                            handleMetadata(
                                              index,
                                              val?.last_time_stamp
                                            ),
                                          0
                                        )
                                      }
                                      width="120px"
                                      height="80px"
                                    />
                                  </CS.EpisodeVideoContainer>
                                  <img
                                    className="play-butttonon"
                                    src={episodePlay}
                                    alt=""
                                    onClick={() => {
                                      if (val?.user_episode_details?.url) {
                                        dispatch(setEpisodeId(val?._id));
                                        dispatch(setVideoPlay(true));
                                        dispatch(setVideoCount(videoCount + 1));
                                      } else {
                                        return "";
                                      }
                                    }}
                                  />
                                  <CS.ProgressContainer>
                                    <CS.ProgressBar
                                      style={{
                                        width: `${percentageTimeRemaining(
                                          val?.user_episode_details
                                            ?.end_time_stamp,
                                          val?.user_episode_details
                                            ?.last_time_stamp
                                        )}%`,
                                      }}
                                    ></CS.ProgressBar>
                                  </CS.ProgressContainer>
                                </CS.MediaPlayerCon>
                              ) : (
                                <img src={val.thumbnailImage} alt="" />
                              )}

                              {val?.user_episode_details?.url ? (
                                <img
                                  className="play-butttonon"
                                  src={episodePlay}
                                  alt=""
                                  onClick={() => {
                                    if (val?.user_episode_details?.url) {
                                      dispatch(setEpisodeId(val?._id));
                                      dispatch(setVideoPlay(true));
                                      dispatch(setVideoCount(videoCount + 1));
                                    } else {
                                      return "";
                                    }
                                  }}
                                />
                              ) : (
                                ""
                              )} */}
                            </CS.ImageBox>
                          </CS.EpisodeImageBox>
                          <CS.EpisodeContentBox>
                            <CS.EpisodeTitleRow>
                              <CS.EpisodeTitle themeColor={theme}>
                                <p>{val.episodeName}</p>
                                {val.episode_completed ? (
                                  <img src={greenCheck} alt="" />
                                ) : (
                                  ""
                                )}
                              </CS.EpisodeTitle>
                              <CS.EpisodeTime themeColor={theme}>
                                <img src={greyClock} alt="" />
                                <p>
                                  {convertTimeFormat(
                                    val.videoTime,
                                    selectLangJson.min
                                  )}
                                </p>
                              </CS.EpisodeTime>
                            </CS.EpisodeTitleRow>
                            <MoreLessEpisode
                              desc={val.description}
                              selectLangJson={selectLangJson}
                            />
                          </CS.EpisodeContentBox>
                        </CS.EpisodeFirst>
                        {/* {val.assessmentQuiz ? (
                                val.quizStatus === "disable" ? (
                                  <CS.EpisodeSecond
                                    themeColor={theme}
                                    style={{ alignItems: "center" }}
                                  >
                                    <CS.CompleteImageBox>
                                      <img
                                        src={
                                          true
                                            ? theme
                                              ? rewardsWatch
                                              : rewardLightsUnWatch
                                            : rewardIcon
                                        }
                                        alt=""
                                      />
                                    </CS.CompleteImageBox>
                                    <CS.SecondInnerFirst>
                                      <CS.CompleteContentBox
                                        watchColor={true}
                                        themeColor={theme}
                                      >
                                        <div className="row-de">
                                          <p className="quiz-title">
                                            {val.assessmentQuiz.title}{" "}
                                          </p>
                                        </div>
                                        <p className="desc-text">
                                          {val.assessmentQuiz.QuestionCount}{" "}
                                          Questions
                                        </p>
                                      </CS.CompleteContentBox>
                                    </CS.SecondInnerFirst>
                                  </CS.EpisodeSecond>
                                ) : (
                                  <CS.EpisodeSecond themeColor={theme}>
                                    <CS.CompleteImageBox>
                                      <img
                                        src={theme ? rewardIcon : rewardLight}
                                        alt=""
                                      />
                                    </CS.CompleteImageBox>
                                    <CS.SecondInnerFirst>
                                      <CS.CompleteContentBox themeColor={theme}>
                                        <div className="row-de">
                                          <p className="quiz-title">
                                            {val.assessmentQuiz.title}{" "}
                                          </p>
                                          <p className="retake-text">
                                            {" "}
                                            (2 Retakes)
                                          </p>
                                          <img src={greenCheck} alt="" />
                                        </div>
                                        <p className="desc-text">
                                          {val.assessmentQuiz.QuestionCount}{" "}
                                          Questions
                                        </p>
                                      </CS.CompleteContentBox>
                                      <CS.ViewResult
                                        onClick={() => {
                                          navigate("/learner/quiz", {
                                            state: {
                                              _id: val.assessmentQuiz._id,
                                            },
                                          });
                                        }}
                                      >
                                        <p>Take Quiz</p>
                                      </CS.ViewResult>
                                    </CS.SecondInnerFirst>
                                  </CS.EpisodeSecond>
                                )
                              ) : (
                                ""
                              )} */}
                        {val.assessmentQuiz
                          ? getQuizStatus(val.quizStatus, val)
                          : ""}
                      </CS.EpisodeContent>
                    </CS.EpisodesCard>
                  );
                }
              )
            )}
          </CS.EpisodeList>
        ) : (
          ""
        )}
        <ModalTwo
          isOpen={isOpenVideo}
          isClose={handleVideoClose}
          handleTrailerPlay={setIsTrailerPlay}
          theme={theme}
          component={
            <SS.VideoContainerSo themeColor={theme}>
              {/* <VideoJS videoURL1={firstPlay} videoURL2={trailerVideoSrc} /> */}
              {isLoaderAdd ? <CircleLoaderTwo themeColor={theme} /> : ""}
              <ReactHlsPlayer
                className="video-class-element"
                style={{
                  display: isLoaderAdd ? "none" : "flex",
                }}
                playerRef={videoPlayerRef}
                src={currentURL}
                muted={false}
                autoPlay={true}
                volume={true}
                width={800}
                height={450}
                onLoadedMetadata={handleMetadatas}
                onEnded={handleVideoEnd}
                controls={currentURL === videoURL1 ? false : true}
              />
            </SS.VideoContainerSo>
          }
        />
        <QS.ButtonGroup themeColor={theme}>
          {/* <QS.CancelButton
            themeColor={theme}
            onClick={() => {
              navigate("/learner/courses/policy-document");
            }}
          >
            {selectLangJson.skip_for_now}
          </QS.CancelButton> */}
          {showNextButton && (
            <QS.SaveButton
              cursor={isNextTab}
              disabled={isNextTab}
              className="buttonload"
              onClick={() => {
                handleNextClick(courseMenuData);
                // nextTab();
              }}
              style={{ padding: "0.6rem 3rem" }}
            >
              {isNextTab ? (
                <i class="fa fa-spinner fa-spin"></i>
              ) : (
                selectLangJson.next
              )}
            </QS.SaveButton>
          )}
        </QS.ButtonGroup>
      </CS.InnerFirstTwo>
      <Modal
        maxWidth={"400px"}
        isOpen={isOpenPolicys}
        // isClose={handleClose}
        backColor={colorProvider.darkBlue}
        component={
          <MS.LeaderContainer themeColor={theme}>
            <MS.LeaderHeader
              style={{ border: "none", justifyContent: "end" }}
              themeColor={theme}
            >
              <MS.CrossButton
                onClick={() => {
                  setIsOpenPolicys(false);
                }}
              >
                <img src={crossIcon} alt="" />
              </MS.CrossButton>
            </MS.LeaderHeader>
            <MS.HeaderBox themeColor={theme}>
              <p className="head-title">{modalMsg}</p>
            </MS.HeaderBox>
          </MS.LeaderContainer>
        }
      />
      <ModalComplete
        isOpen={isPopPo}
        maxWidth="500px"
        theme={theme}
        component={
          <SEC.InnerSectionSuccessSoEpCom
            themeColor={theme}
            style={{ padding: "1rem 0" }}
          >
            <SEC.SuccessIconBoxSo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxSo>
            <SEC.TitleSo themeColor={theme}>
              {selectLangJson?.warning}
            </SEC.TitleSo>
            <SEC.Subtitle themeColor={theme}>
              {selectLangJson?.episode_disable}
            </SEC.Subtitle>
            <QS.ButtonGroup style={{ width: "100%" }} themeColor={theme}>
              <QS.CancelButton
                style={{ width: "100%", padding: "1rem", fontSize: "1rem" }}
                themeColor={theme}
                onClick={() => {
                  setIsPopPo(false);
                }}
              >
                {selectLangJson?.cancel}
              </QS.CancelButton>
            </QS.ButtonGroup>
          </SEC.InnerSectionSuccessSoEpCom>
        }
      />
    </>
  );
};

export default CourseContent;
