import { lazy } from "react";

// Public Route
const Login = lazy(() => import("../pages/public/Login"));
const Forgot = lazy(() => import("../pages/public/Forgot"));
const CheckMail = lazy(() => import("../pages/public/CheckMail"));
const ResetPass = lazy(() => import("../pages/public/ResetPass"));
const Success = lazy(() => import("../pages/public/Success"));
const GetOtp = lazy(() => import("../pages/public/GetOtp"));
const EnterOtp = lazy(() => import("../pages/public/EnterOtp"));
const OtpSuccess = lazy(() => import("../pages/public/OtpSuccess"));
const RedirectPage = lazy(() => import("../pages/public/RedirectPage"));
// Private Route
const Homepage = lazy(() => import("../pages/private/Homepage"));
const Course = lazy(() => import("../pages/private/Courses/Course"));
const Courses = lazy(() =>
  import("../pages/private/Courses/CourseContent/Courses")
);
const CoursesContent = lazy(() =>
  import("../pages/private/Courses/CourseContent/CourseContent")
);
const QuizResult = lazy(() =>
  import("../pages/private/Courses/Quizes/QuizResult")
);
const CourseQuizResult = lazy(() =>
  import("../pages/private/Courses/QuizResult/QuizResult")
);
const QuizResultQues = lazy(() =>
  import("../pages/private/Courses/Quizes/QuizResultQues")
);
const Feedback = lazy(() =>
  import("../pages/private/Courses/Feedback/Feedback")
);
const PolicyDoc = lazy(() =>
  import("../pages/private/Courses/PolicyDoc/PolicyDoc")
);
const Certificate = lazy(() =>
  import("../pages/private/Courses/Certificate/Certificate")
);
const Notification = lazy(() => import("../pages/private/Notification/index"));
const ProfileDetails = lazy(() =>
  import("../pages/private/Profile/ProfileDetails")
);
const Password = lazy(() => import("../pages/private/Profile/Password"));
const CompanyDetails = lazy(() =>
  import("../pages/private/Profile/CompanyDetails")
);
const Committee = lazy(() =>
  import("../pages/private/Courses/Committee/Committee")
);

export const publicRoutes = [
  { path: "/learner", component: Login },
  { path: "/learner/forgot", component: Forgot },
  { path: "/learner/check-mail", component: CheckMail },
  { path: "/learner/reset-password", component: ResetPass },
  { path: "/learner/success", component: Success },
  { path: "/learner/get-otp", component: GetOtp },
  { path: "/learner/otp", component: EnterOtp },
  { path: "/learner/otp-success", component: OtpSuccess },
  { path: "/learner/redirect", component: RedirectPage },
];
export const privateRoutes = [
  { path: "/learner/dashboard", component: Homepage },
  { path: "/learner/course", component: Course },
  // { path: "/learner/courses-content", component: Courses },
  { path: "/learner/quiz-result", component: QuizResult },
  { path: "/learner/quiz", component: QuizResultQues },
  // { path: "/learner/courses/quiz-result", component: CourseQuizResult },
  // { path: "/learner/courses/feedback", component: Feedback },
  // { path: "/learner/courses/policy-document", component: PolicyDoc },
  // { path: "/learner/courses/certificate", component: Certificate },
  { path: "/learner/notification", component: Notification },
  { path: "/learner/profile-details", component: ProfileDetails },
  { path: "/learner/password", component: Password },
  { path: "/learner/company-details", component: CompanyDetails },
];
export const courseRoutes = [
  { path: "/learner/courses-content", component: CoursesContent },
  { path: "/learner/courses/quiz-result", component: CourseQuizResult },
  { path: "/learner/courses/feedback", component: Feedback },
  { path: "/learner/courses/policy-document", component: PolicyDoc },
  { path: "/learner/courses/certificate", component: Certificate },
  { path: "/learner/courses/committee", component: Committee },
];
