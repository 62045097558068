import dashLogo from "../../assets/images/sidebarIcon/lightDashboard.png";

import dashDark from "../../assets/images/sidebarIcon/dashDark.png";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/learner/dashboard",
    activeTab: ["/learner/dashboard"],
    icon: dashLogo,
    activeIcon: dashDark,
    moduleName: null,
  },
  {
    title: "Course",
    path: "/learner/course",
    activeTab: ["/learner/course"],
    icon: dashLogo,
    activeIcon: dashDark,
    moduleName: null,
  },
  
];
