import styled from "styled-components";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const ParentMedia = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 2rem; */
  position: relative;
`;
export const MediaInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  max-height: 700px;
  background-color: black;

  .video-class-element {
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .add-video-class-element {
    width: 100%;
    height: 700px;
    z-index: 0;
  }

  @media print {
    display: none;
  }
`;
export const MusicBox = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
  z-index: 3;
`
export const EpisodeNameRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #1f242fa3;
  position: absolute;
  top: 0;
  z-index: 1;
`;
export const FirstHeader = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_fs};
  padding: 0.5rem 2rem;
  gap: 5px;

  .title {
    font-size: ${fontProvider.oneThree};
    font-weight: ${fontWeight.six};
    font-family: EB Garamond;
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.blackCol};
  }
  .desc {
    font-size: ${fontProvider.zeroSev};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.comLink};
  }
`;
export const QuestionContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: aqua;
  z-index: 999;
`;
export const InnerQuest = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const SubmitButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    outline: none;
    border: none;
    color: black;
    font-size: 1.2rem;
    border-radius: 12px;
    background-color: lightcoral;
    padding: 0.5rem 1rem;
  }
`;
export const InnerFirstTwos = styled.div`
  width: ${commonStyles.wid};
  height: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  /* background-color: ${(props) =>
    props.themeColor
      ? colorProvider.blackThree
      : colorProvider.navBackground}; */
  background-color: #1f242fa3;
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.com_b};
  border-radius: 12px;
  padding: 25px;
  gap: 1rem;
  margin-bottom: 1.7rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  @media (max-width: 410px) {
    padding: 1rem;
  }
`;


export const ClientLogo = styled.div`
  position: absolute;

  z-index: 0;
  margin-top: 1.5rem;
  margin-left: 1.5rem;

  img {
    height: 60px; 
    width: 100%;
    object-fit: contain;
    border-radius: 0px !important;
    border: none !important;
  }

`;
