import React, { useState } from "react";
import styled from "styled-components/macro";
import errorImage from "../assets/images/error.png";
import downArrowIcon from "../assets/images/downArrow.png";
import { useSelector } from "react-redux";
import Select from "react-select";
import "./SelectStyle.css";

const InputBorder = styled.div`
  border: none;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: auto;
  padding: 1px 0px;
  /* border-radius: 8px; */
  width: 100%;
  display: flex;
  background-color: ${(props) => (props.themeColor ? "#161b26" : "#ffffff")};

  img {
    height: 18px;
    width: 18px;
  }

  input {
    font: 1.1rem;
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  }
`;
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
`;
export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-bottom: 0.5rem;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InBox = styled.div`
  display: flex;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  font-size: 0.8rem;
  align-items: center;
  width: 10%;

  img {
    height: 9px;
    width: 9px;
    object-fit: contain;
    margin-left: 4px;
  }
`;

export default function Input(props) {
  const { error, type, icon, noBorderBottom, label, star, optional, erroros } =
    props;
  const [showPassword, setShowPassword] = useState(false);
  const theme = useSelector((state) => state?.isDark);

  const defaultLanguage = window.localStorage.getItem("rcml-lang") || "en";
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '32px', // Set the desired height
      minHeight: '32px',
      outline: state.isFocused ? "none" : null, // Hide outline on focus
      boxShadow: state.isFocused ? "none" : null, // Hide box shadow on focus
      backgroundColor: theme ? "#0C111D" : "#ffffff",
      // border: theme ? "1px solid #333741" : "none", // Border styles
      border: "none", // Border styles
      borderRadius: "8px",
      textTransform: "capitalize",
      "&:hover": {
        outline: "none", // Hide outline on hover
        boxShadow: "none", // Hide box shadow on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "0.8rem", // Set your desired font size for the options
      border: "none", // Border styles
      textTransform: "capitalize",
      fontWeight: "500",
      // backgroundColor: "red",
      // backgroundColor: theme ? "#0C111D" : "#ffffff",
      // color: "#CECFD2",

      backgroundColor: state.isSelected
        ? theme
          ? "#1F242F"
          : "#F6F7F9" // Color for selected option
        : state.isFocused
        ? theme
          ? "#1F242F"
          : "#F6F7F9" // Color for option under mouse pointer
        : theme
        ? "#0C111D"
        : "#ffffff", // Default background color
      color: state.isSelected
        ? theme
          ? "#CECFD2"
          : "#363A44"
        : theme
        ? "#CECFD2"
        : "#363A44", // Text color for options
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: theme ? "#0C111D" : "#ffffff",
      // You can add other properties here to adjust the menu appearance
      border: theme ? "1px solid #333741" : "1px solid #D7DAE0", // Example to remove any border
      borderRadius: "8px", // Example to adjust border radius
      boxShadow: "0px 2px 4px -1px #0000000F, 0px 4px 6px -1px #0000001A",
      width: 'auto', // Allow the menu to expand
      minWidth: '150px',
      right: "0rem"
    }),
    MenuList: (provided, state) => ({
      ...provided,
      padding: "0px !important",
      backgroundColor: `${theme ? "#0C111D" : "#ffffff"} !important`,
      fontWeight: "500",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme ? "#CECFD2" : "#363A44",
      fontSize: "0.8rem", // Font size for the selected value
      fontWeight: "600",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "0.8rem", // Font size for the placeholder
    }),
  };
  return (
    <>
      <InputBox>
        <InputBorder error={error || erroros} themeColor={theme}>
          {icon ? (
            <InBox themeColor={theme}>
              IN <img src={downArrowIcon} alt="" />
            </InBox>
          ) : (
            ""
          )}
          <Select
            styles={customStyles}
            isSearchable={false}
            isClearable={false}
            formatCreateLabel={(userInput) => `${userInput} (Add New Address)`}
            {...props}
          />
          {/* {error ? <img src={errorImage} alt="" /> : ""} */}
        </InputBorder>
        {error || erroros ? (
          <p
            style={{
              paddingTop: 5,
              fontSize: 11,
              color: "red",
            }}
          >
            {error || erroros}
          </p>
        ) : null}
      </InputBox>
    </>
  );
}
