import React, { useEffect, useState } from "react";
import * as CS from "../../../styles/CourseViewStyled";
import * as HS from "../../../styles/HeaderStyled";
import langIcon from "../../../assets/images/langIcon.png";
import darkLang from "../../../assets/images/darkLang.png";
import darkDonwArrow from "../../../assets/images/darkDonwArrow.png";
import downIcon from "../../../assets/images/downIcon.png";
import medalIcon from "../../../assets/images/medalIcon.png";
import cupIcon from "../../../assets/images/cupIcon.png";
import googleIcon from "../../../assets/images/googleIcon.png";
import lightTrophy from "../../../assets/images/lightTrophy.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useSelector, useDispatch } from "react-redux";
import SelectQues from "../../../components/SelectQues";
import axios from "../../../utils/axios";
import { get } from "lodash";
import { setEpisodedSelectLang } from "../../../store/action";
import { Badges } from "../../../utils/api";
import Modal from "../../../components/Modal/Modal";
import { colorProvider } from "../../../utils/StylePropertiy";
import LearnerBadges from "./LearnerBadges";
import ModalComplete from "../../../components/Modal/ModalComplete";
import * as QS from "../../../styles/QuizStyled";
import * as SEC from "../../../styles/SectionStyledTwo";
import successModal from "../../../assets/images/warns.png";



const HeaderView = ({ theme, headData }) => {
  const dispatch = useDispatch();
  const completeProgress = useSelector((state) => state?.completeProgress);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const bannerThumb = useSelector((state) => state?.bannerThumb);
  const episodeLanguages = useSelector((state) => state?.episodeLanguages);
  const episodeSelectLang = useSelector((state) => state?.episodeSelectLang);
  const courseId = useSelector((state) => state?.courseId);
  const [isBadges, setIsBadges] = useState(false);
  const [badgeData, setBadgeData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [isPopPo, setIsPopPo] = useState(false);

  const getBadges = async () => {
    try {
      let url = `${Badges.getBadges}${courseId}`;
      const { data } = await axios.get(url);
      if (data?.data) {
        setBadgeData(data?.data?.url);
      } else {
        setBadgeData([]);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  useEffect(() => {
    if (courseId) {
      getBadges();
    }
  }, [courseId]);

    const handleClose = () => {
      setIsBadges(false)
    }

    const handleConfirmChangeLanguage = () => {
      dispatch(setEpisodedSelectLang(selectedLanguage));
      setIsPopPo(false);
    };

    const handleClosePopup = () => {
      setIsPopPo(false);
    };

  return (
    <CS.CourseInnerHeader themeColor={theme}>
      <CS.HeaderContainer themeColor={theme}>
        <CS.FirstHeader themeColor={theme}>
          <p className="title">{get(bannerThumb, "courseTitle", "")}</p>
          <p className="desc">{get(bannerThumb, "categorie", "")}</p>
        </CS.FirstHeader>
        <HS.ComIconContainersDowns themeColor={theme}>
          <img src={theme ? darkLang : langIcon} alt="" />
          <SelectQues
            options={episodeLanguages}
            star={false}
            value={episodeLanguages?.find(
              (val) => val.value === episodeSelectLang
            )}
            placeholder="Select"
            onChange={(e) => {
              setIsPopPo(true);
              setSelectedLanguage(e.value);
            }}
          />
        </HS.ComIconContainersDowns>
      </CS.HeaderContainer>
      <HS.SecondContainer>
        <HS.BorderContainer
          themeColor={theme}
          className="resp"
        ></HS.BorderContainer>
        <HS.ComIconContainer themeColor={theme}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <HS.CircleBox>
              <CircularProgressbar
                value={completeProgress}
                strokeWidth={"14"}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathTransitionDuration: 0.5,
                  pathColor: `#17B26A`,
                  textSize: "16px",
                  position: "relative",
                  trailColor: theme ? "#333741" : "#D7DAE0",
                })}
              />
              <img
                src={theme ? cupIcon : lightTrophy}
                alt="Your Alt Text"
                style={{
                  position: "absolute",
                  width: "13px", // Adjust size accordingly
                  height: "13px", // Adjust size accordingly
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </HS.CircleBox>
            <HS.CircleText themeColor={theme}>
              {completeProgress}% {window.location.pathname === "/learner/courses/certificate" ? selectLangJson?.completed : selectLangJson?.progress}
            </HS.CircleText>
          </div>
        </HS.ComIconContainer>
        {headData?.googleReview ? (
          <HS.BorderContainer themeColor={theme}></HS.BorderContainer>
        ) : (
          ""
        )}

        {headData?.googleReview ? (
          <HS.GoogleContainer
            target="_blank"
            href={headData?.review}
            themeColor={theme}
          >
            <img src={googleIcon} alt="" />
            <HS.CircleText themeColor={theme}>
              {selectLangJson?.leave_a_review}
            </HS.CircleText>
          </HS.GoogleContainer>
        ) : (
          ""
        )}
        {headData?.badges ? (
          <HS.BorderContainer themeColor={theme}></HS.BorderContainer>
        ) : (
          ""
        )}

        {headData?.badges ? (
          <HS.MedalImage
            onClick={() => {
              setIsBadges(true);
            }}
            themeColor={theme}
          >
            <img src={medalIcon} alt="" />
          </HS.MedalImage>
        ) : (
          ""
        )}
      </HS.SecondContainer>
      <Modal
        maxWidth={"950px"}
        isOpen={isBadges}
        backColor={colorProvider.darkBlue}
        theme={theme}
        component={
          <LearnerBadges
            isClose={handleClose}
            theme={theme}
            badgeData={badgeData}
          />
        }
      />
      <ModalComplete
        isOpen={isPopPo}
        maxWidth="400px"
        theme={theme}
        component={
          <SEC.InnerSectionSuccessSoEpCom
            themeColor={theme}
            style={{ padding: "1rem 0" }}
          >
            <SEC.SuccessIconBoxSo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxSo>
            <SEC.TitleSo themeColor={theme}>
              {selectLangJson?.warning}
            </SEC.TitleSo>
            <SEC.Subtitle themeColor={theme}>
              {selectLangJson?.if_you_change_the_video_language}
            </SEC.Subtitle>
            <QS.ButtonGroup style={{ width: "300px" }} themeColor={theme}>
              <QS.CancelButton
                style={{ width: "50%" }}
                themeColor={theme}
                onClick={() => {
                  handleClosePopup();
                }}
              >
                {selectLangJson?.cancel}
              </QS.CancelButton>
              <QS.SaveButton
                style={{ width: "50%" }}
                themeColor={theme}
                onClick={handleConfirmChangeLanguage}
              >
                {selectLangJson?.ok_continue}
              </QS.SaveButton>
            </QS.ButtonGroup>
          </SEC.InnerSectionSuccessSoEpCom>
        }
      />
    </CS.CourseInnerHeader>
  );
};

export default HeaderView;
