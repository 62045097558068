import React, { useEffect, useState } from "react";
import * as CS from "../../../styles/CourseViewStyled";
import MoreLess from "../../../components/MoreLess";
import darkBook from "../../../assets/images/darkBook.png";
import darkCalender from "../../../assets/images/darkCalender.png";
import darkClock from "../../../assets/images/darkClock.png";
import darkLangSide from "../../../assets/images/darkLangSide.png";
import { useSelector, useDispatch } from "react-redux";
import { Course } from "../../../utils/api";
import axios from "../../../utils/axios";
import { get } from "lodash";
import moment from "moment";
import {
  setEpisodedSelectLang,
  setEpisodeLanguages,
  setLanaguageId,
  setSideContentData,
} from "../../../store/action";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { formatDate } from "../../../utils/Helpers";
import { localeFunction } from "../../../utils/locale";

let descText =
  "The PoSH Awareness Module ensures that you are in compliance with the Law. Further, the module ensures that all Employees are sensitized and there is awareness with respect to their rights and obligations in accordance with the Law. The module through its engaging delivery style decodes the Law and legal jargon for a layperson and takes the user through common scenarios, educating them about the do’s and donts and the appropriate conduct at a workplace.";

const SidebarView = ({ theme, handleLanguageId }) => {
  const dispatch = useDispatch();
  const courseId = useSelector((state) => state?.courseId);
  const sidebarContentData = useSelector((state) => state?.sidebarContentData);
  const [sideviewData, setSideviewData] = useState(sidebarContentData);
  const languageId = useSelector((state) => state?.languageId);
  const selectLang = useSelector((state) => state?.selectLang);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [sideLoading, setSideLoading] = useState(false);

  // console.log(courseId, "courseId");

  const getCourseDetail = async (e) => {
    setSideLoading(true);
    let url = Course.getCourseDetail;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        // languageId: selectLang,
      });
      setSideLoading(false);
      if (data?.result) {
        setSideviewData(data?.result[0]);
        dispatch(
          setEpisodeLanguages(
            data?.result[0]?.languages?.map((val) => {
              return {
                label: val.title,
                value: val._id,
              };
            })
          )
        );
        dispatch(
          setEpisodedSelectLang(get(data?.result[0], "languages[0]._id"))
        );
        // dispatch(setSideContentData(data?.result[0]));
      }
    } catch (error) {
      setSideLoading(false);
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    if (courseId) {
      if (!sidebarContentData) {
        getCourseDetail(courseId);
      }
    }
  }, [courseId]);

  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";
  // console.log(get(sideviewData?.course, "course_baise_info.baiseDescription", ""))

  moment.locale("kn");
  const formattedDateKn = moment(get(sideviewData, "endDate", "")).format(
    "DD MMMM YYYY"
  );
  return sideLoading ? (
    <CS.SecondInner themeColor={theme}>
      <div style={{ marginBottom: "1rem" }}>
        <Skeleton
          width={200}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          height={30}
          style={{ borderRadius: "5px" }}
        />
        <Skeleton
          width={200}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          height={30}
          style={{ borderRadius: "5px", marginTop: "0.5rem" }}
        />
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <Skeleton
          width={200}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          height={30}
          style={{ borderRadius: "5px" }}
        />
        <Skeleton
          width={200}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          height={30}
          style={{ borderRadius: "5px", marginTop: "0.5rem" }}
        />
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <Skeleton
          width={200}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          height={30}
          style={{ borderRadius: "5px" }}
        />
        <Skeleton
          width={200}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          height={30}
          style={{ borderRadius: "5px", marginTop: "0.5rem" }}
        />
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <Skeleton
          width={200}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          height={30}
          style={{ borderRadius: "5px" }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Skeleton
            width={100}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            height={35}
            style={{ borderRadius: "5px", marginTop: "0.5rem" }}
          />
          <Skeleton
            width={100}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            height={35}
            style={{ borderRadius: "5px", marginTop: "0.5rem" }}
          />
          <Skeleton
            width={100}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            height={35}
            style={{ borderRadius: "5px", marginTop: "0.5rem" }}
          />
        </div>
      </div>
    </CS.SecondInner>
  ) : (
    <CS.SecondInner themeColor={theme}>
      <CS.ComContainer>
        <CS.CourseTitle themeColor={theme}>
          <p>{selectLangJson?.description} </p>
        </CS.CourseTitle>
        <MoreLess
          theme={theme}
          selectLangJson={selectLangJson}
          desc={get(
            sideviewData?.course,
            "course_baise_info.baiseDescription",
            ""
          )}
        />
      </CS.ComContainer>
      <CS.ComContainer>
        <CS.CourseTitle themeColor={theme}>
          <img src={darkBook} alt="" />
          <p>{selectLangJson?.course_name}</p>
        </CS.CourseTitle>
        <CS.CourseDesc themeColor={theme}>
          <p>{get(sideviewData, "course.courseTitle", "")}</p>
        </CS.CourseDesc>
      </CS.ComContainer>
      <CS.ComContainer>
        <CS.CourseTitle themeColor={theme}>
          <img src={darkCalender} alt="" />
          <p>{selectLangJson?.due_date}</p>
        </CS.CourseTitle>
        <CS.CourseDesc themeColor={theme}>
          <p>
            {formatDate(
              get(sideviewData, "endDate", ""),
              selectLangJson.date_langugae_key
            )}
          </p>
        </CS.CourseDesc>
      </CS.ComContainer>
      <CS.ComContainer>
        <CS.CourseTitle themeColor={theme}>
          <img src={darkClock} alt="" />
          <p>{selectLangJson.total_video_duration}</p>
        </CS.CourseTitle>
        <CS.CourseDesc themeColor={theme}>
          <p>{get(sideviewData, "course.totalVideoTime", "")}</p>
        </CS.CourseDesc>
      </CS.ComContainer>
      <CS.ComContainer style={{ margin: 0 }}>
        <CS.CourseTitle themeColor={theme}>
          <img src={darkLangSide} alt="" />
          <p>{selectLangJson.available_Languages}</p>
        </CS.CourseTitle>
        <CS.LangButtonRow>
          {get(sideviewData, "languages", [])?.map((val, ind) => {
            return (
              <CS.ComLangButton
                activeColor={languageId === val._id}
                onClick={() => {
                  dispatch(setLanaguageId(val._id));
                }}
                key={ind}
                themeColor={theme}
              >
                <p>{val.title}</p>
              </CS.ComLangButton>
            );
          })}
        </CS.LangButtonRow>
      </CS.ComContainer>
    </CS.SecondInner>
  );
};

export default SidebarView;
