import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import customReducer from "./store/reducers";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./pages/public/AzureLogin/authConfig";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

const store = configureStore({
  reducer: customReducer, // Your root reducer
});
const client = new ApolloClient({
  uri: "http://13.200.55.131:3001/api/v1",
  cache: new InMemoryCache(),
});

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </MsalProvider>
    </React.StrictMode>
  </ApolloProvider>
);
