import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const LearnerContainer = styled.div`
  width: 100%;
  height: 600px;
  overflow-y: auto;
  padding: 2rem;
  border-radius: 12px;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
`;

export const LeaderBadgHeader = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  border-bottom: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  padding-bottom: ${fontProvider.zeroEig};
`;

export const LearnerHeader = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .head-title {
    font-size: ${fontProvider.oneThree};
    font-weight: ${fontWeight.six};
    text-align: ${commonStyles.jc_l};
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.blackCol};
    font-family: EB Garamond;
    @media (max-width: 400px) {
      font-size: ${fontProvider.oneTwo};
    }
  }
  .head-desc {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    text-align: ${commonStyles.jc_l};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyOne : colorProvider.dateColor};
    @media (max-width: 400px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
  .cross-icon {
    height: 15px;
    width: 15px;
    object-fit: contain;
    cursor: pointer;
  }
`;

export const LearnerCardMain = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-top: 2rem;

  @media (max-width: 769px) {
    flex-direction: column;
    padding: 2rem;
  }
`;
export const LearnerCardBadge = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
  margin-top: 2rem;

  @media (max-width: 769px) {
    flex-direction: column;
    padding: 2rem;
  }
`;

export const LearnerBadgesCard = styled.div`
  width: 33.3%;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackFive : colorProvider.navBackground};
  padding: 2.4rem 1.6rem 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

export const CardImg = styled.div`
  img {
    height: 150px;
    width: 150px;
    object-fit: contain;
  }
`;

export const LBCardDetails = styled.div`
  text-align: center;
`;

export const LBCardTitile = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-family: EB Garamond;
  color: ${(props) =>
    props.themeColor ? colorProvider.white : colorProvider.blackCol};
`;

export const LBCardSubtitle = styled.div`
  font-size: 0.7rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  font-family: EB Garamond;
  color: ${(props) =>
    props.themeColor ? colorProvider.white : colorProvider.blackCol};
`;
